import { FC } from "react";

import cls from './_badge.module.scss';

type BadgeProps = {
    children: JSX.Element | string;
    color?: "primary" | "info";
};

export const Badge: FC<BadgeProps> = (props: BadgeProps) => {
    const classNames: string[] = [];
    classNames.push(cls.badge);

    if (props.color)
        classNames.push(cls[props.color]);
    else
        classNames.push(cls.default);

    return (
        <div className={classNames.join(" ")}>
            {props.children}
        </div>
    );
};
