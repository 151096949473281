import { FC, JSX, useEffect, useState } from 'react';
import cls from './_tabs.module.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userStore } from "../../index";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { LocalStorageGet, LocalStorageSet } from "../../services";
import { observer } from "mobx-react-lite";
import { StoredKeys } from "../../AppConstants";

export type TabItem = {
    title: string,
    body: JSX.Element,
}
type TabsProps = {
    tabs: TabItem[]
}

export const Tabs: FC<TabsProps> = observer(({ tabs }) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    useEffect(() => {
        const storedIndex = LocalStorageGet<number>(StoredKeys.ActiveTab)
        setActiveIndex(storedIndex || 0);
    }, []);

    const _onTabClick = (index: number) => () => {
        setActiveIndex(index)
        LocalStorageSet(StoredKeys.ActiveTab, index)
    }
    const _logOut = () => {
        void userStore.logOut()
    }
    return (
        <div className={cls.root}>
            <div className={cls.nav}>
                {tabs.map(({ title }, index) => {
                    return <div className={`${cls.item} ${index === activeIndex ? cls.active : ''}`} onClick={_onTabClick(index)} key={index}>{title}</div>
                })}
                <div className={cls.out}>
                    <FontAwesomeIcon onClick={_logOut} icon={faArrowRightFromBracket} />
                </div>
            </div>
            <div className={cls.body}>
                {tabs[activeIndex]?.body}
            </div>
        </div>
    )
})
