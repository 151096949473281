import { FC, useCallback, useEffect, useState } from 'react'
import { faMinus, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cls from './_body.module.scss'

import { NumberControl } from '../'
import { AirportsTDO, BasketDTO, ServiceDTO } from '../../AppModels'
import { ApiService } from '../../services'
import { ApiUrls } from "../../AppConstants";

type BodyProps = {
    airport: AirportsTDO
    basket: BasketDTO[]
    onBasketChange: (basket: BasketDTO[]) => void
}

export const Body: FC<BodyProps> = ({ basket, onBasketChange, airport }) => {
    const [list, setList] = useState<ServiceDTO[]>([])

    const getServicesList = useCallback(async () => {
        const { data } = await ApiService.getData<ServiceDTO[]>(ApiUrls.ProductsByAirport(airport.Code))
        setList(data)
    }, [airport])

    useEffect(() => {
        void getServicesList()
    }, [getServicesList])

    const serviceItem = (service: ServiceDTO) => {

        const _onCountChange = (count: number) => {
            const newBasket = [...basket]
            const index = newBasket.findIndex(e => e.service.Code === service.Code)
            if (index === -1) {
                newBasket.push({ count: count, service: service })
            } else {
                newBasket[index].count = count
            }
            onBasketChange(newBasket)
        }

        const _onAddClick = () => {
            const newBasket = [...basket]
            const index = newBasket.findIndex(e => e.service.Code === service.Code)
            if (index === -1) {
                newBasket.push({ count: 1, service: service })
            } else {
                newBasket[index].count = newBasket[index].count === 9 ? 9 : ++newBasket[index].count
            }
            onBasketChange(newBasket)
        }

        const _onRemoveClick = () => {
            let newBasket = [...basket]
            const index = newBasket.findIndex(e => e.service.Code === service.Code)
            if (index !== -1) {
                if (newBasket[index].count === 1) {
                    newBasket = newBasket.filter(e => e.service.Code !== service.Code)
                } else {
                    newBasket[index].count = --newBasket[index].count
                }
            }
            onBasketChange(newBasket)
        }

        const isInBasket = basket.findIndex(e => e.service.Code === service.Code) !== -1

        return (
            <div key={service.Code} className={cls.item}>
                <div className={cls.text}>
                    <div className={cls.prev} data-icon={service.Code.toLowerCase()}></div>
                    <div className={cls.name}>{service.DisplayName}</div>
                    <div className={cls.price}>{airport.Currency.toUpperCase()} {service.Price.toFixed(2)}</div>
                </div>
                <div className={cls.controls}>
                    {isInBasket ?
                        <>
                            <div className={cls.action} onClick={_onRemoveClick}>
                                <FontAwesomeIcon className={cls.icon} icon={faMinus} />
                            </div>
                            <div className={cls.action}>
                                <NumberControl onValueChange={_onCountChange}
                                               value={basket.find(e => e.service.Code === service.Code)?.count || 0}
                                               className={cls.field} />
                            </div>
                            <div className={cls.action} onClick={_onAddClick}>
                                <FontAwesomeIcon className={cls.icon} icon={faPlus} />
                            </div>
                        </>
                        :
                        <div className={cls.new} onClick={_onAddClick}>Add <FontAwesomeIcon className={cls.icon}
                                                                                            icon={faPlusCircle} /></div>
                    }
                </div>
            </div>
        )
    }

    const _getItems = (regExp: string) => {
        const _getPosition = (pos: string): number => +pos.split('|')[0].split(':')[1]
        return list.filter(e => new RegExp(`(${regExp})`).test(e.SortOrder)).sort((a, b) => _getPosition(a.SortOrder) - _getPosition(b.SortOrder)).map(s => serviceItem(s))
    }

    return (
        <div className={cls.root}>
            <div className={cls.row}>
                <div className={cls.col}>
                    {_getItems('L1')}
                </div>
                <div className={cls.col}>
                    {_getItems('L2')}
                </div>
            </div>
        </div>
    )
}


