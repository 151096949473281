import cls from './_login.module.scss'
import { Button } from "../UI/Button";
import { observer } from "mobx-react-lite";
import { userStore } from "../../index";
import { useEffect } from "react";

export const Login = observer(() => {
    useEffect(() => {
        void userStore.clearUserData()
    }, [])
    return (
        <div className={cls.root}>
            <Button label="Login" isInline={true} onClick={userStore.logIn} />
        </div>
    )
})
