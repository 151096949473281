/* eslint-disable no-mixed-operators */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { AdminLayout } from "../../AdminLayout/AdminLayout";
import { AirportsTDO, ServiceDTO, ServiceOverruleDTO } from "../../../AppModels";
import { ApiService, PromiseCompletion } from "../../../services";
import { ApiUrls } from "../../../AppConstants";
import { TextControl, DatePicker, ModalAction, ModalDialog } from "../../";
import cls from "../_configuration.module.scss";
import DateTimeService from "../../../services/DateTimeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faInfinity, faTrash, faBarsStaggered } from "@fortawesome/free-solid-svg-icons";


export const Stations = () => {
    const [dataLoader] = useState<PromiseCompletion>(new PromiseCompletion())
    const [pageLoader] = useState<PromiseCompletion>(new PromiseCompletion())
    const [airports, setAirports] = useState<AirportsTDO[]>([])
    const [selected, setSelected] = useState<AirportsTDO | null>(null)
    const [products, setProducts] = useState<ServiceDTO[]>([])
    const [overrule, setOverrule] = useState<ServiceOverruleDTO[]>([])

    const getAirportsList = useCallback(async () => {
        const params = {}
        const { data } = await ApiService.getData<AirportsTDO[]>(ApiUrls.AirportUrl, params, { completion: pageLoader })
        setAirports(data)
    }, [pageLoader])

    const loadOverrules = useCallback(async () => {
        if (selected) {
            const { data } = await ApiService.postData<ServiceOverruleDTO[]>(ApiUrls.PricesByAirport(selected.Code), null, { completion: dataLoader })
            setOverrule(data)
        }
    }, [selected, dataLoader])

    const loadProducts = useCallback(async () => {
        if (selected) {
            const productsReq = ApiService.getData<ServiceDTO[]>(ApiUrls.ProductsByAirport(selected.Code), null)
            const overrulesReq = ApiService.postData<ServiceOverruleDTO[]>(ApiUrls.PricesByAirport(selected.Code), null)
            const prodData = Promise.all([productsReq, overrulesReq])
            dataLoader.subscribe(prodData)
            const [products, overrules] = await prodData
            setProducts(products.data)
            setOverrule(overrules.data)
        }
    }, [selected, dataLoader])


    useEffect(() => {
        void getAirportsList()
    }, [getAirportsList])

    useEffect(() => {
        void loadProducts()
    }, [selected, loadProducts]);

    const title = selected ? <>{selected.Code} &mdash; {selected.Name}</> : ``;

    const listItems = airports.map(a => {
        return { id: a.Code, label: `${a.Code} - ${a.Name}` }
    })

    const _onAirportSelect = (id: string) => {
        setSelected(airports.find(a => a.Code === id) || null)
    }

    const _onProductChange = () => {
        void loadProducts()
    }

    const _onOverrulesChange = () => {
        void loadOverrules()
    }

    const _getItems = (regExp: string) => {
        const _getPosition = (pos: string): number => +pos.split('|')[0].split(':')[1]
        if (!selected) return null;
        return products.filter(e => new RegExp(`(${regExp})`)
            .test(e.SortOrder))
            .sort((a, b) => _getPosition(a.SortOrder) - _getPosition(b.SortOrder))
            .map(s => {
                const overrules = overrule.filter(e => e.ProductCode === s.Code)
                return (
                    <ServiceItem
                        key={s.Code}
                        service={s}
                        station={selected}
                        loader={dataLoader}
                        ruleList={overrules}
                        onCommissionChange={_onProductChange}
                        onChange={_onOverrulesChange} />
                )
            })
    }

    return (
        <AdminLayout
            list={listItems}
            onItemSelect={_onAirportSelect}
            title={title}
            dataLoader={dataLoader}
            pageLoader={pageLoader}
        >
            <div className={cls.list}>
                <div className={cls.col}>
                    {_getItems('L1')}
                </div>
                <div className={cls.col}>
                    {_getItems('L2')}
                </div>
            </div>
        </AdminLayout>
    )
}

type StationItemProps = {
    service: ServiceDTO
    ruleList: ServiceOverruleDTO[]
    station: AirportsTDO,
    loader: PromiseCompletion
    onChange: () => void
    onCommissionChange: () => void
}

const ServiceItem: FC<StationItemProps> = (props) => {
    const {
        service, station,
        loader, onChange,
        onCommissionChange, ruleList
    } = props;
    const { DisplayName, Price } = service;
    const [item, setItem] = useState<ServiceDTO>(service)
    const [ruleDialog, setRuleDialog] = useState<boolean>(false)
    const [commission, setCommission] = useState<ServiceDTO | null>(null)
    const [rule, setRule] = useState<ServiceOverruleDTO | null>(null)
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date | null>(null)

    const _createRule = useCallback(() => {
        setRule({ Amount: service.Price })
        setFromDate(null)
        setToDate(null)
    }, [service, setRule, setFromDate, setToDate])
    
    const _onEditCommission = (e: React.MouseEvent) => {
        e.stopPropagation();
        setCommission(service)
    }
    const _cancelCommissionEdit = useCallback(() => {
            setCommission(null);
        },
        [setCommission],
    );

    const _onEditRules = useCallback(() => {
        setRuleDialog(!ruleDialog)
        if (!ruleList.length) {
            _createRule()
        }
    }, [setRuleDialog, ruleDialog, ruleList, _createRule])
    const _closeRuleDialog = useCallback(() => {
            setRuleDialog(false);
        },
        [setRuleDialog],
    );
    const _cancelRuleEdit = useCallback(() => {
            setRule(null);
            if (!ruleList.length) {
                _closeRuleDialog()
            }
        },
        [setRule, ruleList, _closeRuleDialog],
    );


    useEffect(() => {
        setItem(service)
        return () => {
            _cancelCommissionEdit()
        }
    }, [service, _cancelCommissionEdit]);
    const _onCommissionChange = (val: string) => {
        const valNum = +val.trim()
        if (commission && val !== '' && !isNaN(valNum) && valNum >= 0 && valNum <= 100) {
            setCommission({ ...commission, Commission: valNum })
        }
    }
    const _updateCommission = async () => {
        if (commission) {
            const params = {
                percent: commission?.Commission,
            }
            await ApiService.postData(ApiUrls.Commission(station.Code, service.Code), params, { completion: loader })
            setCommission(null);
            onCommissionChange()
        }
    }

    const _updateRule = async () => {
        console.log('_updateOverrule')
        const params = {
            airportCode: station.Code,
            productCode: item.Code,
            amount: rule?.Amount,
            dateFrom: fromDate ? DateTimeService.dateUtc(fromDate) : undefined,
            dateTo: toDate ? DateTimeService.dateUtc(toDate) : undefined,
        }
        await ApiService.postData<ServiceOverruleDTO>(ApiUrls.OverrulePrice, params, { completion: loader })
        setRule(null);
        onChange()
    }

    const _onPriceChange = (val: string) => {
        if (rule) {
            const valNum = +val.trim()
            if (val !== '' && !isNaN(valNum) && valNum >= 0) {
                setRule({ ...rule, Amount: valNum })
            }
        }
    }

    const _setFrom = (date: Date | null) => {
        setFromDate(date)
        if (toDate && date && date > toDate) {
            setToDate(date)
        }
    }
    const _setTo = (date: Date | null) => {
        setToDate(date)
        if (fromDate && date && date < fromDate) {
            setFromDate(date)
        }
    }

    const _deleteRule = (id?: string) => async () => {
        if (id) {
            const overLen = ruleList.length;
            await ApiService.deleteData(ApiUrls.DeleteOverruleById(id), { completion: loader })
            if (overLen === 1) {
                _closeRuleDialog()
                _cancelRuleEdit()
            }
            onChange()
        }
    }

    const _commissionBody = (<>
        <div className={cls.label}>Name</div>
        <TextControl
            inpClass={cls.field}
            disabled={true}
            value={DisplayName}
        />
        <div className={cls.label}>Commission</div>
        <TextControl
            inpClass={cls.field}
            type="number"
            isAutoFocus={true}
            autoSelect={true}
            mask={v => {
                const newV = +v.replace(/\D/g, '')
                if (newV > 100) return `100`
                if (newV < 0) return `0`
                return newV.toString()
            }}
            value={`${item.Commission}`}
            onValueChange={_onCommissionChange} />
    </>)
    const _commissionActions: ModalAction[] = [
        {
            label: 'Cancel',
            onClick: _cancelCommissionEdit,
            className: cls.mrAuto
        },
        {
            label: 'Save',
            onClick: _updateCommission,
        }
    ]

    const _ruleBody = (<>
        <div className={cls.label}>Name</div>
        <TextControl
            inpClass={cls.field}
            disabled={true}
            value={DisplayName}
        />
        <div className={cls.label}>Price</div>
        <TextControl
            inpClass={cls.field}
            type="number"
            isAutoFocus={true}
            autoSelect={true}
            mask={v => v.replace(/\D/g, '')}
            value={rule?.Amount || 0}
            onValueChange={_onPriceChange} />

        <div className={cls.row}>
            <div className={cls.col}>
                <div className={cls.label}>From</div>
                <DatePicker
                    selected={fromDate}
                    onChange={_setFrom}
                    isClearable
                />
            </div>
            <div className={cls.col}>
                <div className={cls.label}>To</div>
                <DatePicker
                    selected={toDate}
                    onChange={_setTo}
                    isClearable
                />
            </div>
        </div>
    </>)
    const _ruleListBody = (<>
        <table className={cls.overrule}>
            <thead>
            <tr>
                <th>From</th>
                <th>To</th>
                <th>Price</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {ruleList.map(o => {
                const fromDate = o.DateFrom ? DateTimeService.fromString(o.DateFrom) : null
                const toDate = o.DateTo ? DateTimeService.fromString(o.DateTo) : null
                return (
                    <tr key={o.Id}>
                        <td>{fromDate ? DateTimeService.formatLocal(fromDate) : <FontAwesomeIcon icon={faInfinity} />}</td>
                        <td>{toDate ? DateTimeService.formatLocal(toDate) : <FontAwesomeIcon icon={faInfinity} />}</td>
                        <td className={cls.overrulePrice}>{o.Amount} {station.Currency}</td>
                        <td>
                            <div className={cls.actions}>
                                <span className={cls.btnTrash} onClick={_deleteRule(o.Id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </span>
                            </div>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    </>)

    const _ruleListActions: ModalAction[] = [
        {
            label: 'Close',
            onClick: _closeRuleDialog,
            className: cls.mrAuto
        },
        {
            label: 'Add Rule',
            onClick: _createRule
        }
    ]
    const _ruleActions: ModalAction[] = [
        {
            label: 'Cancel',
            onClick: _cancelRuleEdit,
            className: cls.mrAuto
        },
        {
            label: rule?.Id ? 'Update' : 'Create',
            onClick: _updateRule
        }
    ]
    const activeRule = ruleList.find(r=>{
        const from = r.DateFrom ? DateTimeService.fromString(r.DateFrom).getTime() : 0; 
        const to = r.DateTo ? DateTimeService.fromString(r.DateTo).getTime() : 0;
        const now = DateTimeService.now().getTime();
        return (
            from === 0 && to === 0 ||
            from === 0 && now <= to ||
            to === 0 && now >= from ||
            now >= from && now <= to 
        )
    })
    return (
        <>
            <div className={`${cls.item} ${ruleDialog || !!commission ? cls.active : ''}`} onClick={_onEditRules}>
                <div className={cls.name}>{DisplayName}</div>
                <div className={`${cls.info} ${ruleList.length && activeRule ? cls.changed : ''}`}>
                    {activeRule ? <FontAwesomeIcon className={cls.icon} icon={faLightbulb} /> : !!ruleList.length ? <FontAwesomeIcon className={cls.icon} icon={faBarsStaggered} /> : null }
                    {`${Price} ${station.Currency}`}
                </div>
                <div className={cls.actions}>
                    <span className={`${cls.btnText} ${cls.changed}`} onClick={_onEditCommission}>
                        +{service.Commission}<small>%</small>
                    </span>
                </div>
            </div>
            <ModalDialog
                show={ruleDialog}
                title={rule ? rule.Id ? 'Edit Rule' : 'New Rule' : ruleList.length ? 'Overrules' : ''}
                body={rule ? _ruleBody : _ruleListBody}
                actions={rule ? _ruleActions : _ruleListActions} />
            <ModalDialog
                show={!!commission}
                title="Edit Commission"
                body={_commissionBody}
                actions={_commissionActions} />
        </>
    )
}
