import { useCallback, useEffect, useState } from 'react'
import { PaymentConfirmationDTO, PaymentState } from '../../AppModels'
import { AppLoader } from '../AppLoader/AppLoader'
import cls from './_result.module.scss'
import { useSearchParams } from 'react-router-dom'
import { ApiService } from '../../services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleStop, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons'
import DateTimeService from '../../services/DateTimeService'
import { ApiUrls } from "../../AppConstants";

export const Result = () => {
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('orderId') || ''
    const [payment, setPayment] = useState<PaymentConfirmationDTO | null>(null)

    const getPayment = useCallback(async () => {
        try {
            const { data: payment } = await ApiService.getData<PaymentConfirmationDTO>(ApiUrls.PaymentStatusById(orderId))
            setPayment(payment)
        } catch (e) {
            setPayment({
                State: PaymentState.Failed,
                Message: (e as Error)?.message
            })
        }
    }, [orderId])

    useEffect(() => {
        if (orderId) {
            void getPayment()
        }
    }, [getPayment, orderId])

    if (!payment) return <AppLoader showLoader={true} />

    if (payment.State === PaymentState.Failed) {
        return (
            <div className={cls.root}>
                <div className={cls.error}><FontAwesomeIcon className={cls.icon} icon={faXmark} /></div>
                <div className={cls.title}>Oh no!<br />Payment was unsuccessful.</div>
                <div className={cls.text}>Payment provider was not able to process your payment ({payment.Message}).
                    Please try again.
                </div>
            </div>
        )
    }

    if (payment.State === PaymentState.Canceled) {
        return (
            <div className={cls.root}>
                <div className={cls.cancel}><FontAwesomeIcon className={cls.icon} icon={faTriangleExclamation} /></div>
                <div className={cls.title}>Payment was cancelled.</div>
                <div className={cls.text}>We aren't able to process your payment. Please try again.</div>
            </div>
        )
    }

    if (payment.State === PaymentState.Ordered) {
        return (
            <div className={cls.root}>
                <div className={cls.cancel}><FontAwesomeIcon className={cls.icon} icon={faCircleStop} /></div>
                <div className={cls.title}>Payment was suspended.</div>
                <div className={cls.text}>Please try again.</div>
            </div>
        )
    }

    return (
        <div className={cls.wrapper}>
            <div className={cls.header}></div>
            <table className={cls.rootTable}>
                <thead>
                <tr>
                    <td>
                        <div className={cls.headerSpace}>&nbsp;</div>
                    </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <div className={cls.confirmation}>
                            <div className={cls.h1}>Payment Confirmation</div>
                            <div className={cls.p}>Dear
                                Mr./Mrs. <b>{payment.CustomerName || ''} {payment.CustomerSurname || ''}</b></div>
                            <div className={cls.p}>We are pleased to confirm your payment
                                dated <b>{DateTimeService.formatLocal(payment.DepartureDateLt ? new Date(payment.DepartureDateLt) : new Date())}</b> as
                                follows:
                            </div>
                            <table className={cls.table}>
                                <tbody>
                                <tr>
                                    <th>Flight</th>
                                    <th>Date</th>
                                    <th><span className={cls.desc}>Quantity</span><span className={cls.mobi}>Qty</span>
                                    </th>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Total Price</th>
                                </tr>
                                {payment.Products?.map(p => {
                                    return (
                                        <tr key={p.Code}>
                                            <td>{payment.FlightNumber}</td>
                                            <td>{payment.DepartureDateLt ? DateTimeService.formatLocal(new Date(payment.DepartureDateLt), 'DDMMMYY') : ''}</td>
                                            <td>{p.Quantity}</td>
                                            <td>{p.DisplayName}</td>
                                            <td>{payment.Currency} {p.Price}</td>
                                            <td>{payment.Currency} {p.TotalPrice}</td>
                                        </tr>
                                    )
                                })}
                                <tr className={cls.total}>
                                    <td colSpan={5}>Total</td>
                                    <td>{payment.Currency} {payment.TotalPrice}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td>
                        <div className={cls.footerSpace}>&nbsp;</div>
                    </td>
                </tr>
                </tfoot>
            </table>
            <div className={cls.footer}>
                <b>Edelweiss Air AG</b><br />
                The Circle 32<br />
                P. O. Box<br />
                8058 Zurich Airport<br />
                Switzerland
            </div>
        </div>
    )
}
