import React from "react";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import { ApiService, PromiseCompletion } from "../../../services";
import { AdminLayout } from "../../AdminLayout/AdminLayout";
import { ListItem } from "../../List/List";
import { ApiUrls, KeyboardCodes } from "../../../AppConstants";
import { Button } from "../../UI/Button";
import { loaderStore } from "../../..";
import { AirportDto, EmailsInfo, ReportPeriod, ReportType } from "../../../AppModels";
import { TextControl } from "../../UI/FormControls";
import { Badge } from "../../UI/Badge";

import cls from "./_reports.module.scss";

@observer
export class ReportsConfigurationPage extends React.Component<{}, {}> {
    private _dataLoader = new PromiseCompletion();
    private _pageLoader = new PromiseCompletion();

    @observable
    private _airports: AirportDto[] = [];

    @observable
    private _selected?: AirportDto;

    constructor(props: {}) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <AdminLayout list={this._listItems} onItemSelect={this._onAirportSelect} title={this._title} dataLoader={this._dataLoader} pageLoader={this._pageLoader}>
                {this._selected && <Details airport={this._selected} onSave={this._onAirportChange} />}
            </AdminLayout>
        );
    }

    componentDidMount() {
        void this._getAirportsList();
    }

    @computed
    private get _listItems() {
        return this._airports.map((a) => {
            return { id: a.Code, label: `${a.Code} - ${a.Name}` } as ListItem;
        });
    }

    @computed
    private get _title() {
        return this._selected ? (
            <>
                {this._selected.Code} &mdash; {this._selected.Name}
            </>
        ) : (
            ``
        );
    }

    @action.bound
    private async _getAirportsList() {
        const params = {};
        const { data } = await ApiService.getData<AirportDto[]>(ApiUrls.AirportUrl, params, { completion: this._pageLoader });
        this._airports = data;
    }

    @action.bound
    private _onAirportChange = (airport: AirportDto) => {
        this._airports = this._airports.map((a) => (a.Code === airport.Code ? airport : a));
    };

    @action.bound
    private _onAirportSelect = (id: string) => {
        this._selected = this._airports.find((a) => a.Code === id);
    };
}

type DetailsProps = {
    airport: AirportDto;
    onSave: (airport: AirportDto) => void;
};

@observer
export class Details extends React.Component<DetailsProps, {}> {
    @observable.ref
    private _config!: AirportDto;

    constructor(props: DetailsProps) {
        super(props);
        makeObservable(this);
    }

    render() {
        this._config = this.props.airport;

        return (
            <>
                <div className={cls.group}>
                    <table className={cls.table}>
                        <thead>
                            <tr>
                                <th />
                                <th />
                                <th style={{ width: "25%" }}>PDF</th>
                                <th style={{ width: "25%" }}>CSV</th>
                                <th style={{ width: "25%" }}>XLS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this._renderRow("Daily", ReportPeriod.DailyReports, this._config.DailyReports)}
                            {this._renderRow("Weekly", ReportPeriod.WeeklyReports, this._config.WeeklyReports)}
                            {this._renderRow("Monthly", ReportPeriod.MonthlyReports, this._config.MonthlyReports)}
                        </tbody>
                    </table>
                </div>
                <div className={cls.controls}>
                    <Button isInline={true} onClick={this._onSave} disabled={!this._config.MonthlyReports?.[ReportType.Pdf]?.To} label="Save" />
                </div>
            </>
        );
    }

    private _renderRow(title: string, period: ReportPeriod, src?: { [key: string]: EmailsInfo }) {
        return (
            <>
                <tr className={cls.horizontalDelimiter}>
                    <td rowSpan={2} className={cls.firstColumn}>
                        {title}
                    </td>
                    <td className={cls.upperHint}>
                        <div className={cls.hintText}>To:</div>
                    </td>
                    {this._renderEmails(period, "To", src)}
                </tr>
                <tr className={cls.horizontalSubDelimiter}>
                    <td className={cls.lowerHint}>
                        <div className={cls.hintText}>Cc:</div>
                    </td>
                    {this._renderEmails(period, "Cc", src)}
                </tr>
            </>
        );
    }

    private _renderEmails(period: ReportPeriod, field: keyof EmailsInfo, src?: { [key: string]: EmailsInfo }) {
        const keys = [ReportType.Pdf, ReportType.Csv, ReportType.Xls];
        return <>{keys.map((key) => this._renderEmailsInfo(src?.[key], period, key, field))}</>;
    }

    private _renderEmailsInfo(info: EmailsInfo | undefined, period: ReportPeriod, type: ReportType, field: keyof EmailsInfo) {
        return (
            <>
                <td className={cls.emails}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", flexWrap: "wrap" }}>
                        {info?.[field] ? (
                            <>
                                {info?.[field]?.map((x) => (
                                    <Badge>
                                        <div style={{ display: "flex", padding: "0 8px", gap: "8px" }}>
                                            {x}{" "}
                                            <FontAwesomeIcon
                                                icon={faClose}
                                                style={{ cursor: "pointer" }}
                                                color="red"
                                                onClick={() =>
                                                    this._updateEmailsInfo(
                                                        period,
                                                        type,
                                                        field,
                                                        info?.[field]!.filter((t) => t !== x)
                                                    )
                                                }
                                            />
                                        </div>
                                    </Badge>
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div style={{ padding: "4px" }}>
                        <TextControl
                            style={{ padding: "0px" }}
                            clearOnEnter
                            onEnter={(val) => {
                                val = val.trim();
                                if (val) this._updateEmailsInfo(period, type, field, [...(info?.[field] ?? []), val]);
                            }}
                        />
                    </div>
                </td>
            </>
        );
    }

    @action
    private _updateEmailsInfo(period: ReportPeriod, type: ReportType, field: keyof EmailsInfo, value: string[]) {
        let src: { [key: string]: EmailsInfo };

        if (period === ReportPeriod.DailyReports) {
            if (!this._config.DailyReports) this._config.DailyReports = {};

            src = this._config.DailyReports;
        } else if (period === ReportPeriod.WeeklyReports) {
            if (!this._config.WeeklyReports) this._config.WeeklyReports = {};

            src = this._config.WeeklyReports;
        } else {
            if (!this._config.MonthlyReports) this._config.MonthlyReports = {};

            src = this._config.MonthlyReports;
        }

        if (!src[type]) src[type] = {};

        src[type][field] = !value || value.length === 0 ? undefined : value.filter((x, index) => value.indexOf(x) === index);
    }

    private _addEnterListener() {
        document.addEventListener("keydown", (event: KeyboardEvent) => {
            if (event.code === KeyboardCodes.Enter) {
                document.removeEventListener("keydown", this._addEnterListener);
            }
        });
    }

    @action.bound
    private async _onSave() {
        await ApiService.postData(ApiUrls.AirportUrl, this._config, { completion: loaderStore.bodyLoader });
        this.props.onSave(this._config);
    }
}
