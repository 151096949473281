import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { AppRouter } from './App'
import './App.scss'
import { LoaderStore } from './stores/LoaderStore'
import { UserStore } from "./stores/UserStore";
import { AppLoader } from './components'
import { BrowserRouter } from 'react-router-dom'
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import config from './config.json'

const rootContainer = document.getElementById('root') as Element
const root = createRoot(rootContainer)
const msalConfig = { ...config.msalConfig }
msalConfig.auth.redirectUri = window.location.origin;
const msalInstance = new PublicClientApplication(msalConfig);

export const loaderStore = new LoaderStore()
export const userStore = new UserStore(msalInstance)

const AppWithCallbackAfterRender = () => {
    useEffect(() => {
        const loader = document.getElementById('react-app-loader')
        if (loader) {
            loaderStore.appLoaderElement = loader
            loader.addEventListener('transitionend', () => {
                if (loader.classList.contains('hide')) {
                    loader.classList.add('invisible')
                }
            }, true)
            loaderStore.hideAppLoader()
        }
    })
    return (
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <AppRouter />
                <AppLoader />
            </MsalProvider>
        </BrowserRouter>
    )
}

root.render(<AppWithCallbackAfterRender />)

window.addEventListener('load', async () => {
    if (navigator.serviceWorker) {
        try {
            const reg = await navigator.serviceWorker.register(`${window.location.origin}/sw.js`)
            console.log('SW:Registration successful with scope: ', reg.scope)
        } catch (e) {
            console.log('SW:Registration failed: ', e)
        }
    }
})
