import { FC, JSX, PropsWithChildren } from 'react';
import cls from "./_adminLayout.module.scss"
import { AppLoader } from "../AppLoader/AppLoader";
import { List, ListItem } from "../List/List";
import { PromiseCompletion } from "../../services";
import { observer } from "mobx-react-lite";

type AdminLayoutProps = {
    list?: ListItem[],
    onItemSelect?: (selectedId: string) => void
    title?: string | JSX.Element
    titleActions?: JSX.Element
    dataLoader?: PromiseCompletion
    pageLoader?: PromiseCompletion
    centerMode?:boolean
}

export const AdminLayout: FC<PropsWithChildren & AdminLayoutProps> = observer((props) => {
    const {
        list, title,
        onItemSelect, children,
        dataLoader, pageLoader,
        titleActions, centerMode
    } = props;
    const contentClass = [cls.content]
    centerMode && contentClass.push(cls.middle)
    return (
        <div className={cls.root}>
            {pageLoader?.isPending ?
                <AppLoader className={cls.loader} showLoader={true} />
                :
                <>
                    <div className={cls.sidebar} hidden={!list?.length}>
                        {list && <List items={list} onSelect={onItemSelect} />}
                    </div>
                    <div className={cls.body}>
                        <div className={cls.wrapper}>
                            <div className={contentClass.join(' ')}>
                                {(title || titleActions) && 
                                    <div className={cls.subTitle}>
                                        <div className={cls.title}>{title}</div>
                                        <div className={cls.actions}>{titleActions}</div>
                                    </div>
                                }
                                {children}
                            </div>
                            <AppLoader className={cls.loader} showLoader={dataLoader?.isPending} />
                        </div>
                    </div>
                </>
            }
        </div>
    )
})
