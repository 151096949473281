import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, CancelToken, CancelTokenSource, ResponseType } from 'axios';
import { makeAutoObservable } from 'mobx';
import qs from 'qs';
import config from '../config.json';

import DateTimeService from './DateTimeService';
import { PromiseCompletion } from './PromiseCompletion';
import { userStore } from '../index';

export type ApiHeaders = {
    [key: string]: string | number;
}

export enum ApiHeadersKeys {
    CacheControl = 'Cache-Control',
    Authorization = 'Authorization',
    ContentType = 'Content-Type',
}

export type AjaxOptions = {
    responseType?: ResponseType;
    noDateTransform?: boolean;
    completion?: PromiseCompletion;
    cancelDuplicatedRequests?: boolean;
    cancellationToken?: CancelToken;
}

type AxiosMethodDefinition = (url: string, data?: any, config?: AxiosRequestConfig) => AxiosPromise;
type AxiosMethodChooser = (instance: AxiosInstance) => AxiosMethodDefinition;

export class CancelTokenService {
    private _cancelTokenCollection: Map<string, CancelTokenSource> = new Map();

    public newCancelTokenSource () {
        return axios.CancelToken.source();
    }

    public addAndCheckCancelToken (tokenKey: string, source: CancelTokenSource) {
        const duplicateToken = this._cancelTokenCollection.get(tokenKey);
        if (duplicateToken) {
            duplicateToken.cancel();
        }
        this._cancelTokenCollection.set(tokenKey, source);
    }

    public newCancellationToken (requestKey?: string) {
        if (requestKey) {
            const source = this.newCancelTokenSource();
            this.addAndCheckCancelToken(requestKey, source);
            return source.token;
        }
        return undefined;
    }
}

export class ApiService {
    private static _cancelTokenService = new CancelTokenService();

    constructor () {
        makeAutoObservable(this);
    }

    public static putData<TResponse> (url: string, putData?: any, options?: AjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.put, url, putData, options);
    }

    public static postData<TResponse> (url: string, postData?: any, options?: AjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.post, url, postData, options);
    }

    public static deleteData<TResponse> (url: string, options?: AjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.delete, url, {}, options);
    }

    public static getData<TResponse> (url: string, getData?: any, options?: AjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.get, url, {
            params: getData, paramsSerializer: (params: any) => qs.stringify(params, { indices: false })
        }, options);
    }

    public static handleError (url: string, error: any) {
        if (axios.isCancel(error)) {
            console.log(`Request canceled: ${url}`);
            return;
        }
        if (error && error.response && error.response.status === 409) {
            return;
        }
        errorHandleService.showError(url, error);
    }

    static typeCheck = (el: any) => {
        if (!el) return el;
        let typeEl = el;
        switch (typeof el) {
            case 'string':
                typeEl = ApiService.strCheck(el);
                break;
            case 'object':
                typeEl = Array.isArray(el) ? ApiService.arrCheck(el) : ApiService.objCheck(el);
                break;
        }
        return typeEl;
    };

    private static _callMethod<TResponse> (methodChooser: AxiosMethodChooser, url: string, data?: any, options?: AjaxOptions): AxiosPromise<TResponse> {
        const requestKey = this._getAbsoluteUrl(url);

        let cancelToken = options?.cancellationToken;
        if (options?.cancelDuplicatedRequests) {
            const source = this._cancelTokenService.newCancelTokenSource();
            cancelToken = source.token;
            this._cancelTokenService.addAndCheckCancelToken(requestKey, source);
        }

        const instance = ApiService._getInstance(options, cancelToken);
        const result = methodChooser(instance)(requestKey, data);
        options && options.completion && options.completion.subscribe(result);
        result.catch((error: any) => {
            this.handleError(url, error);
        });
        return result;
    }

    private static _getAbsoluteUrl (url: string): string {
        let newUrl = url;
        if (newUrl && newUrl[0] !== '/') newUrl = '/' + url;
        if (!newUrl.startsWith('/api')) newUrl = '/api/' + url;
        return config.api.url + newUrl;
    }

    private static strCheck = (str: string) => {
        if (DateTimeService.ISO_8601_date.test(str)) return DateTimeService.fromString(str);
        return str;
    };

    private static arrCheck = (array: any) => {
        return array.map((el: any) => {
            return ApiService.typeCheck(el);
        });
    };

    private static objCheck = (obj: any) => {
        Object.keys(obj).forEach(key => {
            obj[key] = ApiService.typeCheck(obj[key]);
        });
        return obj;
    };

    private static _getInstance (options?: AjaxOptions, cancelToken?: CancelToken): AxiosInstance {
        const headers: ApiHeaders = {};
        headers[ApiHeadersKeys.ContentType] = headers[ApiHeadersKeys.ContentType] || 'application/json';
        headers[ApiHeadersKeys.CacheControl] = headers[ApiHeadersKeys.CacheControl] || 'no-cache';
        headers[ApiHeadersKeys.Authorization] = headers[ApiHeadersKeys.Authorization] || 'Bearer ' + userStore.currentToken;
        const transformResponse = (data: any) => {
            if (options && options.noDateTransform) return data;
            return ApiService.typeCheck(data);
        };
        const axiosConfig: AxiosRequestConfig = {
            responseType: options?.responseType || 'json',
            headers: headers,
            cancelToken: cancelToken
        };
        const instance = axios.create(axiosConfig);
        instance.interceptors.response.use(response => {
            response.data = transformResponse(response.data);
            return response;
        });
        return instance;
    }
}

class ErrorHandleService {
    private _lastError: { message: string, time: number } | null = null;

    showError (url: string, error: any) {
        const currentTime = (new Date()).getTime();
        console.log('--error AJAX: ', error);
        if (!this._lastError || this._lastError.message !== error.message || (currentTime - this._lastError.time > 10000)) {
            if (error.response && error.response.status === 401) {
                // void authService.signOut()
            }
            if (error.response && error.response.status !== 403) {
                console.error('--error AJAX', error.message.toString());
                if (error && error.response && (error.response.status === 400 || error.response.status === 500)) {
                    console.error(error.message.toString());
                }
                this._lastError = { message: error.message, time: currentTime };
            }
        }
    }
}

const errorHandleService = new ErrorHandleService();
