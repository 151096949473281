import { FC, useCallback, useEffect, useState } from 'react';
import { ProductDTO } from "../../../AppModels";
import { ApiService, PromiseCompletion } from "../../../services";
import { ApiUrls } from "../../../AppConstants";
import { AdminLayout } from "../../AdminLayout/AdminLayout";
import cls from "../_configuration.module.scss";
import { TextControl } from "../../UI/FormControls";
import { ModalAction, ModalDialog } from "../../UI/Dialog";

export const Products = () => {
    const [dataLoader] = useState<PromiseCompletion>(new PromiseCompletion())
    const [products, setProducts] = useState<ProductDTO[]>([])
    const loadProducts = useCallback(async () => {
        const { data } = await ApiService.getData<ProductDTO[]>(ApiUrls.Products, null, { completion: dataLoader })
        setProducts(data)
    }, [dataLoader])
    useEffect(() => {
        void loadProducts()
    }, [loadProducts])

    const _onServiceChange = () => {
        void loadProducts()
    }
    const _getItems = (regExp: string) => {
        const _getPosition = (pos: string): number => +pos.split('|')[0].split(':')[1]
        return products.filter(e => new RegExp(`(${regExp})`)
            .test(e.SortOrder))
            .sort((a, b) => _getPosition(a.SortOrder) - _getPosition(b.SortOrder))
            .map(s => <ServiceItem key={s.Code} service={s} loader={dataLoader} onChange={_onServiceChange} />)
    }

    return (
        <AdminLayout
            title=""
            centerMode={true}
            dataLoader={dataLoader}
        >
            <div className={cls.list}>
                <div className={cls.col}>
                    {_getItems('L1')}
                </div>
                <div className={cls.col}>
                    {_getItems('L2')}
                </div>
            </div>
        </AdminLayout>
    )
}
type ServiceItemProps = {
    service: ProductDTO
    loader: PromiseCompletion
    onChange: () => void
}

const ServiceItem: FC<ServiceItemProps> = ({ service, loader, onChange }) => {
    const [editMode, setEditMode] = useState<boolean>(false)
    const [item, setItem] = useState<ProductDTO>(service)
    const _toEdit = () => {
        setEditMode(!editMode)
    }

    const _cancelEdit = useCallback(() => {
        setEditMode(false);
        setItem(service)
    }, [setEditMode, setItem, service])

    useEffect(() => {
        setItem(service)
        return () => {
            _cancelEdit()
        }
    }, [service, _cancelEdit]);

    const _onNameChange = (val: string) => {
        setItem({ ...item, DisplayName: val.trim() })
    }
    const _onOrderChange = (val: string) => {
        setItem({ ...item, SortOrder: val.trim() })
    }


    const _saveEdit = async () => {
        const params = {
            code:item.Code,
            displayName:item.DisplayName,
            sortOrder:item.SortOrder
        }
        await ApiService.putData<ProductDTO>(ApiUrls.Products, params, { completion: loader })
        setEditMode(false);
        onChange()
    }

    const _editBody = (
        <>
            <div className={cls.label}>Service Name</div>
            <TextControl
                inpClass={cls.field}
                isAutoFocus={true}
                value={item?.DisplayName}
                onValueChange={_onNameChange} />
            <div className={cls.label}>Sort Order (L#:#|P#:#)</div>
            <TextControl
                inpClass={cls.field}
                value={item?.SortOrder}
                onValueChange={_onOrderChange} />
        </>
    )
    const _editActions: ModalAction[] = [
        {
            label: 'Cancel',
            onClick: _cancelEdit,
            className: cls.mrAuto
        },
        {
            label: 'Save',
            onClick: _saveEdit,
            disabled: !item.DisplayName || !item.SortOrder
        }]
    return (
        <>
            <div className={`${cls.item} ${editMode ? cls.active : ''}`} onClick={_toEdit}>
                <div className={cls.name}>{service?.DisplayName}</div>
                <div className={cls.order}>{service?.SortOrder}</div>
            </div>
            <ModalDialog
                show={editMode}
                title="Edit Service"
                body={_editBody}
                actions={_editActions} />
        </>
    )
}
