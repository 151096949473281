export class Routing {
    public static readonly Home: string = '/';
    public static readonly Result: string = '/result';
    public static readonly Login: string = '/account/login';
    public static readonly Commerce: string = '/commerce';
    public static readonly Configuration: string = '/configuration';
}

export class ApiUrls {
    public static readonly AirportUrl: string = 'airport';
    public static readonly Regions: string = 'regions';
    public static readonly Region: string = 'region';
    public static readonly Payment: string = 'payment';
    public static readonly Prices: string = 'prices';
    public static readonly Configuration: string = 'configuration';
    public static readonly Products: string = 'products';
    public static readonly PriceForRegion: string = ApiUrls.Prices + '/' + ApiUrls.Region;
    public static readonly OverrulePrice: string = ApiUrls.Prices + '/' + ApiUrls.AirportUrl;

    public static ProductsByAirport (airportId: string) {
        return `${ApiUrls.AirportUrl}/${airportId}/${ApiUrls.Products}`;
    }

    public static PricesByAirport (airportId: string) {
        return `${ApiUrls.Prices}/${ApiUrls.AirportUrl}/${airportId}`;
    }

    public static Commission (airportId: string, productCode: string) {
        return `commission/${airportId}/${productCode}`;
    }

    public static ProductsByRegion (regionId: string) {
        return `${ApiUrls.Regions}/${regionId}/${ApiUrls.Products}`;
    }

    public static DeleteProductFromRegion (regionId: string, productCode: string) {
        return `${ApiUrls.Products}/${ApiUrls.Region}/${regionId}/${productCode}`;
    }

    public static DeleteOverruleById (overruleId: string) {
        return `${ApiUrls.Prices}/${ApiUrls.AirportUrl}/${overruleId}`;
    }

    public static FlightByAirport (airportId: string) {
        return `flight/${airportId}`;
    }

    public static PaymentStatusById (paymentId: string) {
        return `${ApiUrls.Payment}/${paymentId}`;
    }

    public static ConfigurationByAirport (airportCode: string) {
        return `${ApiUrls.Configuration}/${airportCode}`;
    }

}

export class StoredKeys {
    public static readonly Token: string = 'currentToken';
    public static readonly User: string = 'currentUser';
    public static readonly ActiveTab: string = 'tabIndex';
}

export class KeyboardCodes {
    public static readonly Escape: string = 'Escape';
    public static readonly Enter: string = 'Enter';

}