import { FC, useCallback, useEffect, useState } from 'react'
import cls from './_airportsList.module.scss'
import { Button, TextControl } from '../'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ApiService } from '../../services'
import { AirportsTDO } from '../../AppModels'
import { loaderStore } from '../../index'
import { ApiUrls } from "../../AppConstants";

type AirportsListProps = {
    onSelect: (pos: AirportsTDO) => void
}

export const AirportsList: FC<AirportsListProps> = ({ onSelect }) => {
    const [airports, setAirports] = useState<AirportsTDO[]>([])
    const [search, setSearch] = useState<string>('')
    const [pos, setPos] = useState<AirportsTDO | null>(null)

    const getAirportsList = useCallback(async () => {
        const { data } = await ApiService.getData<AirportsTDO[]>(ApiUrls.AirportUrl + "?withFlightsOnly=true", null, { completion: loaderStore.appLoader })
        setAirports(data)
    }, [])

    useEffect(() => {
        void getAirportsList()
    }, [getAirportsList])

    const onClick = (pos: AirportsTDO) => () => {
        setPos(pos)
    }

    const renderItem = (item: AirportsTDO) => {
        return (
            <div className={cls.item} key={item.Code} onClick={onClick(item)}>
                {pos?.Code === item.Code && <FontAwesomeIcon className={cls.icon} icon={faCheck} />}
                {`${item.Code} - ${item.Name}`}
            </div>
        )
    }

    const _onSearch = (val: string) => {
        setSearch(val)
    }

    const _onPositionSelect = () => {
        pos && onSelect(pos)
    }

    return (
        <div className={cls.root}>
            <div className={cls.header}>
                <TextControl showIcon={true} inpClass={cls.search} wrapClass={cls.searchWrap} label="Search"
                             value={search} onValueChange={_onSearch} />
            </div>
            <div className={cls.body}>
                {airports.filter(e => search ? e.Name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || e.Code.toLowerCase().indexOf(search.toLowerCase()) !== -1 : !search).map(e => renderItem(e))}
            </div>
            <div className={cls.footer}>
                <Button label="Ok" disabled={!pos} onClick={_onPositionSelect} />
            </div>
        </div>
    )
}
