export type RegionTDO = {
    Id: string
    DisplayName: string
    Curency: string
}

export type AirportsTDO = {
    Code: string
    Name: string
    Currency: string
    CountryTwoLetterCode?: string;
}

export type FlightDTO = {
    AirlineRcd: string
    FlightNumber: string
    DepartureAirport: string
    ArrivalAirport: string
    Id: string
    ScheduledTimeDepartureLt: Date
}

export type ServiceDTO = {
    Code: string
    DisplayName: string
    Quantity: number
    Price: number
    SortOrder: string
    Commission: number
    TotalPrice: number
}
export type ServiceOverruleDTO = {
    Id?: string
    AirportCode?: string
    ProductCode?: string
    DateFrom?: string
    DateTo?: string
    Amount: number
}

export type RegionPriceDTO = {
    Id: string
    RegionId: string
    ProductCode: string
    Amount: number
}

export type PaymentDTO = {
    Id: string
    TransactionId: string
    TotalPrice: number
    Currency: string
    CallUrl: string
}
export type ProductDTO = {
    Code: string
    DisplayName: string
    SortOrder: string
}

export enum PaymentState {
    Ordered = 'Ordered',
    Payed = 'Payed',
    Failed = 'Failed',
    Canceled = 'Canceled',
}

export type PaymentStatusDTO = {
    TransactionId: string
    TotalPrice: number
    State: PaymentState
}

export type BasketDTO = {
    service: ServiceDTO
    count: number
}

export type PaymentConfirmationDTO = {
    TransactionId?: number
    State: PaymentState
    FlightNumber?: string
    DepartureDateLt?: Date
    Airport?: string
    Message?: string
    CustomerName?: string
    CustomerSurname?: string
    TotalPrice?: number
    Currency?: string
    Products?: ServiceDTO[]
}

export enum UserRole {
    Station = '0',
    Ground = '1',
    Commercial = '2',
    Admin = '3'
}

export type UserDTO = {
    name?: string
    role: UserRole
}

export type ConfigurationDTO = {
    AirportCode: string
    DailyReports: ReportType[]
    WeeklyReports: ReportType[]
    MonthlyReports: ReportType[]
    EmailsTo: string[]
    EmailsCc?: string[]
}

export enum ReportPeriod {
    DailyReports = 'dailyReports',
    WeeklyReports = 'weeklyReports',
    MonthlyReports = 'monthlyReports'
}

export enum ReportType {
    None = "None",
    Pdf = "Pdf",
    Csv = "Csv",
    Xls = "Xls",
}

export class AirportDto {
    Code?: string;
    Name?: string;
    DailyReports?: { [key: string]: EmailsInfo; };
    WeeklyReports?: { [key: string]: EmailsInfo; };
    MonthlyReports?: { [key: string]: EmailsInfo; };
}

export class EmailsInfo {
    To?: string[];
    Cc?: string[];
}