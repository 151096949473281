//eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function () {
    //use custom implementation for better performance
    let year = this.getUTCFullYear().toString();
    let month = (this.getUTCMonth() + 1).toString();
    let day = this.getUTCDate().toString();
    let hh = this.getUTCHours().toString();
    let mm = this.getUTCMinutes().toString();
    let ss = this.getUTCSeconds().toString();
    if (month.length === 1)
        month = '0' + month;
    if (day.length === 1)
        day = '0' + day;
    if (hh.length === 1)
        hh = '0' + hh;
    if (mm.length === 1)
        mm = '0' + mm;
    if (ss.length === 1)
        ss = '0' + ss;

    return `${year}-${month}-${day}T${hh}:${mm}:${ss}Z`;
};
export default class DateTimeService {
    static ISO_8601_date: RegExp = /^\d{4}-\d\d-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?$/i;
    static regExpDateMask: RegExp = /(YYYY|YY|MMMM|MMM|MM|M|DD|D|dd|d|HH|H|mm|ss|SSS)/g;
    static monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    static monthShortNames: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    static weekDayNames2Letters: string[] = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

    static fromString (value: string): Date {
        return new Date(Date.parse(value));
    }

    static now (): Date {
        return new Date();
    }

    static date (year: number, month: number, day: number): Date {
        return new Date(year, month, day, 0, 0, 0, 0);
    }
    
    static dateUtc(date: Date): Date {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
    }
    static formatLocal (date: Date, format: string = 'DD.MM.YYYY'): string {
        let result = format;
        result = result.replace(this.regExpDateMask, (match: string) => {
            switch (match) {
                case 'DD':
                    return date.getDate() < 10 ? '0' + date.getDate() : date.getDate().toString();
                case 'D':
                    return date.getDate().toString();
                case 'dd':
                    let dayOfWeek = date.getDay() - 1;
                    if (dayOfWeek < 0) {
                        dayOfWeek = 6;
                    }
                    return this.weekDayNames2Letters[dayOfWeek];
                case 'MMMM':
                    return this.monthNames[date.getMonth()];
                case 'MMM':
                    return this.monthShortNames[date.getMonth()];
                case 'MM':
                    return date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
                case 'M':
                    return (date.getMonth() + 1).toString();
                case 'YYYY':
                    return date.getFullYear().toString();
                case 'YY':
                    return date.getFullYear().toString().substr(-2);
                case 'HH':
                    return date.getHours() < 10 ? '0' + date.getHours() : date.getHours().toString();
                case 'H':
                    return date.getHours().toString();
                case 'mm':
                    return date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes().toString();
                case 'ss':
                    return date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds().toString();

                default:
                    return match;
            }
        });
        return result;
    }

    static formatUtc (date: Date, format: string = 'DD.MM.YYYY'): string {
        let result = format;
        result = result.replace(this.regExpDateMask, (match: string) => {
            switch (match) {
                case 'DD':
                    return date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate().toString();
                case 'D':
                    return date.getUTCDate().toString();
                case 'dd':
                    let dayOfWeek = date.getUTCDay() - 1;
                    if (dayOfWeek < 0) {
                        dayOfWeek = 6;
                    }
                    return this.weekDayNames2Letters[dayOfWeek];
                case 'MMMM':
                    return this.monthNames[date.getUTCMonth()];
                case 'MMM':
                    return this.monthShortNames[date.getUTCMonth()];
                case 'MM':
                    return date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1).toString() : (date.getUTCMonth() + 1).toString();
                case 'M':
                    return (date.getUTCMonth() + 1).toString();
                case 'YYYY':
                    return date.getUTCFullYear().toString();
                case 'YY':
                    return date.getUTCFullYear().toString().substr(-2);
                case 'HH':
                    return date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours().toString();
                case 'H':
                    return date.getUTCHours().toString();
                case 'mm':
                    return date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes().toString();
                case 'ss':
                    return date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds().toString();

                default:
                    return match;
            }
        });
        return result;
    }

    static addTime (date: Date, hours: number, minutes?: number): Date {

        let offset = hours * 60 * 60 * 1000;
        if (minutes) {
            offset += minutes * 60 * 1000;
        }

        return new Date(date.getTime() + offset);
    }

    static isAfter (d1: Date, d2: Date): boolean {
        return d1.getTime() - d2.getTime() > 0;
    }

    static isBefore (d1: Date, d2: Date): boolean {
        return d2.getTime() - d1.getTime() > 0;
    }

    static today (): Date {
        let today = new Date();
        return DateTimeService.date(today.getFullYear(), today.getMonth(), today.getDate());
    }
}
