import { AdminLayout } from "../../AdminLayout/AdminLayout";
import { FC, useCallback, useEffect, useState } from "react";
import { ApiService, PromiseCompletion } from "../../../services";
import { ProductDTO, RegionPriceDTO, RegionTDO, ServiceDTO } from "../../../AppModels";
import { ApiUrls } from "../../../AppConstants";
import cls from "../_configuration.module.scss";
import { TextControl } from "../../UI/FormControls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faFloppyDisk, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ModalAction, ModalDialog } from "../../UI/Dialog";
import { Button } from "../../UI/Button";
import { AxiosPromise } from "axios";

export const Regions = () => {
    const [dataLoader] = useState<PromiseCompletion>(new PromiseCompletion())
    const [pageLoader] = useState<PromiseCompletion>(new PromiseCompletion())
    const [regions, setRegions] = useState<RegionTDO[]>([])
    const [selected, setSelected] = useState<RegionTDO | null>(null)
    const [products, setProducts] = useState<ServiceDTO[]>([])
    const [allProducts, setAllProducts] = useState<ProductDTO[]>([])
    const [addProducts, setAddProducts] = useState<string[]>([])
    const [dialog, setDialog] = useState<boolean>(false)

    const getRegionsList = useCallback(async () => {
        const { data } = await ApiService.getData<RegionTDO[]>(ApiUrls.Regions, null, { completion: pageLoader })
        setRegions(data)
    }, [pageLoader])

    const getAllProducts = useCallback(async () => {
        const { data } = await ApiService.getData<ProductDTO[]>(ApiUrls.Products, null, { completion: pageLoader })
        setAllProducts(data)
    }, [pageLoader])

    const loadProducts = useCallback(async () => {
        if (selected) {
            const { data } = await ApiService.getData<ServiceDTO[]>(ApiUrls.ProductsByRegion(selected.Id), null, { completion: dataLoader })
            setProducts(data)
        }
    }, [selected, dataLoader])

    useEffect(() => {
        void getRegionsList()
        void getAllProducts()

    }, [getRegionsList, getAllProducts])

    useEffect(() => {
        void loadProducts()
    }, [selected, loadProducts]);

    const listItems = regions.map(a => {
        return { id: a.Id, label: a.DisplayName }
    })

    const _onRegionSelect = (id: string) => {
        setSelected(regions.find(a => a.Id === id) || null)
    }

    const _onProductChange = () => {
        void loadProducts()
    }
    const addList = allProducts.filter(p => products.findIndex(l => l.Code === p.Code) === -1)
    const _closeDialog = () => {
        setDialog(false)
        setAddProducts([])
    }

    const _addService = async () => {
        if (selected && addProducts.length) {
            const addServices: AxiosPromise[] = []
            addProducts.forEach(code => {
                const config = {
                    regionId: selected.Id,
                    productCode: code,
                    amount: 999
                }
                addServices.push(ApiService.postData<RegionPriceDTO>(ApiUrls.PriceForRegion, config))
            })
            const addRequest = Promise.all(addServices);
            dataLoader.subscribe(addRequest)
            await addRequest
            await loadProducts()
            _closeDialog()
        }
    }
    const _addProduct = (
        <Button
            isInline={true}
            isSmall={true}
            className={cls.add}
            label="+ Add Service"
            onClick={() => {
                setDialog(true)
            }} />
    )
    const _addActions: ModalAction[] = [
        {
            label: 'Close',
            onClick: _closeDialog,
            className: cls.mrAuto
        },
        {
            label: 'Add',
            onClick: _addService
        }]
    const _toggleProduct = (p: string) => () => {
        setAddProducts(addProducts.includes(p) ? addProducts.filter(e => e !== p) : [...addProducts, p])
    }
    const _addBody = (
        <>
            {addList.map(e => {
                return (
                    <div key={e.Code} className={`${cls.product} ${addProducts.includes(e.Code) ? cls.checked : ''}`} onClick={_toggleProduct(e.Code)}>
                        {e.DisplayName}
                    </div>
                )
            })}
        </>
    )
    const _renderItem = (p: ServiceDTO) => {
        if(!selected) return null;
        return (
            <ServiceItem key={p.Code} service={p} region={selected} loader={dataLoader} onChange={_onProductChange} />
        )
    }
    return (
        <AdminLayout
            list={listItems}
            onItemSelect={_onRegionSelect}
            title={selected?.DisplayName || ``}
            titleActions={selected && addList.length ? _addProduct : undefined}
            dataLoader={dataLoader}
            pageLoader={pageLoader}
        >
            {products.length ? products.map(_renderItem) : null}
            <ModalDialog
                show={dialog}
                title="Add Service To Region"
                body={_addBody}
                actions={_addActions} />
        </AdminLayout>
    )
}

type ServiceItemProps = {
    service: ServiceDTO
    region: RegionTDO,
    loader: PromiseCompletion
    onChange: () => void
}

const ServiceItem: FC<ServiceItemProps> = ({ service, region, loader, onChange }) => {
    const { DisplayName, Price } = service
    const [edit, setEdit] = useState<boolean>(false)
    const [confirm, setConfirm] = useState<boolean>(false)
    const [value, setValue] = useState<number>(Price)
    const [valid, setValid] = useState<boolean>(true)
    const _toEdit = () => {
        setEdit(!edit)
    }
    const _cancelEdit = useCallback(
        () => {
            setEdit(false);
            setConfirm(false);
            setValid(true)
            setValue(service.Price)
        },
        [setEdit, setConfirm, setValid, setValue, service],
    );
    

    useEffect(() => {
        setValue(service.Price)
        return () => {
            _cancelEdit()
        }
    }, [service.Price, _cancelEdit]);

    const _onChange = (value: string) => {
        const newVal = +value;
        if (value !== '' && !isNaN(newVal) && newVal >= 0) {
            setValue(newVal)
            setValid(true)
        } else {
            setValid(false)
        }
    }
    const _saveEdit = async () => {
        const config = {
            regionId: region.Id,
            productCode: service.Code,
            amount: value
        }
        await ApiService.postData<RegionPriceDTO>(ApiUrls.PriceForRegion, config, { completion: loader })
        setEdit(false);
        setValid(true)
        onChange()
    }
    const _deleteService = async () => {
        await ApiService.deleteData<RegionPriceDTO>(ApiUrls.DeleteProductFromRegion(region.Id, service.Code), { completion: loader })
        onChange()
    }
    const _onDeleteClick = () => {
        setConfirm(true)
    }
    const _editActions: ModalAction[] = [
        {
            label: 'No',
            onClick: () => {
                setConfirm(false)
            },
            className: cls.mrAuto
        },
        {
            label: 'Yes',
            onClick: _deleteService
        }]
    return (
        <div className={cls.root}>
            <div className={cls.name}>{DisplayName}</div>
            <div className={cls.price}>
                {edit ?
                    <TextControl
                        inpClass={`${cls.input} ${valid ? '' : cls.invalid}`}
                        type="number"
                        onEscape={_cancelEdit}
                        onEnter={_saveEdit}
                        isAutoFocus={true}
                        autoSelect={true}
                        mask={v => v.replace(/\D/g, '')}
                        onValueChange={_onChange}
                        value={value} />
                    :
                    Price}
                <span>{region.Curency}</span>
            </div>
            <div className={cls.field}>

            </div>
            <div className={cls.actions}>
                {edit ?
                    <>
                        <>
                            <span className={cls.btnRed} onClick={_cancelEdit}>
                                <FontAwesomeIcon icon={faBan} />
                            </span>
                            <span className={cls.btnGreen} onClick={_saveEdit}>
                                <FontAwesomeIcon icon={faFloppyDisk} />
                            </span>
                        </>
                    </>
                    :
                    <>
                        <span className={cls.btn} onClick={_toEdit}>
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </span>
                        <span className={cls.btnTrash} onClick={_onDeleteClick}>
                            <FontAwesomeIcon icon={faTrash} />
                        </span>
                    </>
                }
            </div>
            <ModalDialog
                show={confirm}
                title="Delete Service From Region"
                actions={_editActions} />
        </div>
    )
}