import { ChangeEvent, FC, InputHTMLAttributes, KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import cls from "./_textControl.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { KeyboardCodes } from "../../AppConstants";

type TextControlProps = InputHTMLAttributes<HTMLInputElement> & {
    onValueChange?: (value: string) => void;
    label?: string;
    value?: string | number;
    mask?: (val: string) => string;
    onEnter?: (val: string) => void;
    onEscape?: (val: string) => void;
    wrapClass?: string;
    inpClass?: string;
    showIcon?: boolean;
    isAutoFocus?: boolean;
    clearOnEnter?: boolean;
    autoSelect?: boolean;
};

export const TextControl: FC<TextControlProps> = (props) => {
    const { onValueChange, label, value, inpClass, wrapClass, showIcon, isAutoFocus, mask, onEnter, onEscape, autoSelect, clearOnEnter, ...rest } = props;

    const input = useRef<HTMLInputElement>(null);
    const [val, setVal] = useState<string | number>("");
    const inpClsList = [cls.root];
    inpClass && inpClsList.push(inpClass);
    const wrapClsList = [cls.wrapper];
    wrapClass && wrapClsList.push(wrapClass);

    const _onFocus = useCallback(() => {
        setTimeout(() => {
            input.current?.focus();
            if (autoSelect) {
                input.current?.select();
            }
        }, 0);
    }, [input, autoSelect]);

    useEffect(() => {
        setVal(value || "");
    }, [value]);

    useEffect(() => {
        if (isAutoFocus && input) {
            _onFocus();
        }
    }, [isAutoFocus, _onFocus]);

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = mask ? mask(e.target.value) : e.target.value;
        setVal(newValue);
        onValueChange && onValueChange(newValue);
    };

    const _onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case KeyboardCodes.Enter: {
                onEnter && onEnter(val.toString());
                if (clearOnEnter) setVal("");
                break;
            }
            case KeyboardCodes.Escape: {
                onEscape && onEscape(val.toString());
                break;
            }
        }
    };

    const _onClear = () => {
        if (val) {
            setVal("");
            onValueChange && onValueChange("");
        }
    };

    if (label) {
        return (
            <div className={wrapClsList.join(" ")}>
                <input ref={input} className={inpClsList.join(" ")} value={val} onChange={_onChange} placeholder={label} onClick={_onFocus} onKeyUp={_onKeyUp} onFocus={_onFocus} {...rest} />
                {showIcon && (
                    <div className={cls.icon}>
                        <FontAwesomeIcon onClick={_onClear} icon={!!val ? faXmark : faMagnifyingGlass} />
                    </div>
                )}
            </div>
        );
    }

    return <input ref={input} className={inpClsList.join(" ")} value={val} onChange={_onChange} placeholder={label} onClick={_onFocus} onKeyUp={_onKeyUp} onFocus={_onFocus} {...rest} />;
};

type NumberControlProps = InputHTMLAttributes<HTMLInputElement> & {
    onValueChange: (value: number) => void;
    value?: number;
};

export const NumberControl: FC<NumberControlProps> = ({ onValueChange, value, ...rest }) => {
    const [val, setVal] = useState<number>(0);
    useEffect(() => {
        setVal(value || 0);
    }, [value]);

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newVal = +e.target.value;
        if (isNaN(newVal)) {
            newVal = 0;
        } else {
            if (newVal > 9) {
                newVal = 9;
            } else if (newVal < 1) {
                newVal = 0;
            }
        }
        setVal(newVal);
        onValueChange(newVal);
    };

    return <input type="number" value={val} onChange={_onChange} {...rest} />;
};

type SwitchControlProps = InputHTMLAttributes<HTMLInputElement> & {
    onSwitch: (value: boolean) => void;
};

export const SwitchControl: FC<SwitchControlProps> = ({ onSwitch, checked, ...rest }) => {
    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
        onSwitch(e.target.checked);
    };

    return (
        <label className={cls.switch}>
            <input type="checkbox" checked={checked} onChange={_onChange} {...rest} />
            <span />
        </label>
    );
};
