import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, PropsWithChildren } from 'react'
import cls from './_button.module.scss'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string
    isInline?: boolean
    isSmall?: boolean
    onClick: () => void
}

export const Button: FC<PropsWithChildren & ButtonProps> = (props) => {
    const {
        onClick, label, isInline,
        className, isSmall, children,
        ...rest
    } = props;
    const clsList = [cls.root];
    !isInline && clsList.push(cls.full)
    isSmall && clsList.push(cls.small)
    className && clsList.push(className)
    return (
        <button className={clsList.join(' ')}
                onClick={onClick} {...rest}>{label}{children}</button>
    )
}

type ButtonLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
    label: string
    isSmall?: boolean
    isInline?: boolean
    onClick?: () => void
}
export const ButtonLink: FC<ButtonLinkProps> = (props) => {
    const {
        onClick, label, isInline,
        className, isSmall, ...rest
    } = props;
    const clsList = [cls.root];
    !isInline && clsList.push(cls.full)
    isSmall && clsList.push(cls.small)
    className && clsList.push(className)
    return (
        <a className={clsList.join(' ')}
           onClick={onClick} {...rest}>{label}</a>
    )
}