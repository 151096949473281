import React, { useState } from 'react'
import './App.scss'
import { LocalStorageGet, LocalStorageSet } from './services'
import { AirportsList, Configuration, Login, Main, Result } from './components'
import { AirportsTDO } from './AppModels'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Routing } from "./AppConstants";

const KEY_AIRPORT = 'airportCode'

export const App = () => {
    const selectedPos = LocalStorageGet<AirportsTDO>(KEY_AIRPORT)
    const [pos, setPos] = useState<AirportsTDO | null>(selectedPos)

    const _onPositionSet = (position: AirportsTDO) => {
        setPos(position)
        LocalStorageSet<AirportsTDO>(KEY_AIRPORT, position)
    }
    if (!pos) return <AirportsList onSelect={_onPositionSet} />
    return <Main airport={pos} />
}

export const AppRouter = () => {
    return (
        <Routes>
            <Route path={Routing.Home} element={<App />} />
            <Route path={Routing.Result} element={<Result />} />
            <Route path={Routing.Login} element={<Login />} />
            <Route path={Routing.Configuration} element={<Configuration />} />
            <Route path="*" element={<Navigate replace to={Routing.Home} />} />
        </Routes>
    )
}

