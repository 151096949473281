import { FC, useCallback, useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import QRCode from 'react-qr-code'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateBackward, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import "react-phone-input-2/lib/style.css";
import cls from './_main.module.scss'

import { AirportsTDO, BasketDTO, FlightDTO, PaymentDTO, PaymentState, PaymentStatusDTO } from '../../AppModels'
import { Body, Button, ButtonLink, Header, ModalAction, TextControl } from '../'
import { ModalDialog } from '../UI/Dialog'
import { ApiService, LocalStorageClear } from '../../services'
import { loaderStore } from '../../index'
import DateTimeService from '../../services/DateTimeService'
import config from '../../config.json'
import { ApiUrls } from "../../AppConstants";

type MainProps = {
    airport: AirportsTDO
}

enum View {
    ZeroFlights,
    Services,
    User,
    Reset
}

export const Main: FC<MainProps> = ({ airport }) => {
    const [flightList, setFlightList] = useState<FlightDTO[]>([])
    const [view, setView] = useState<View>(View.Services)
    const [flight, setFlight] = useState<FlightDTO | null>(null)
    const [showFlightList, setShowFlightList] = useState<boolean>(false)
    const [customerName, setCustomerName] = useState<string>('')
    const [customerSurname, setCustomerSurname] = useState<string>('')
    const [customerPNR, setCustomerPNR] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [basket, setBasket] = useState<BasketDTO[]>([])
    const [payment, setPayment] = useState<PaymentDTO | null>(null)
    const [status, setStatus] = useState<PaymentStatusDTO | null>(null)
    const [statusTimeout, setStatusTimeout] = useState<number>(0)
    const _statusCheckInterval = 3000

    const getFlightList = useCallback(async () => {
        const { data } = await ApiService.getData<FlightDTO[]>(ApiUrls.FlightByAirport(airport.Code), null, { completion: loaderStore.appLoader })
        setFlightList(data)
    }, [airport])

    useEffect(() => {
        void getFlightList()
    }, [getFlightList])

    useEffect(() => {
        const count = flightList.length
        if (!!count) {
            setView(View.Services)
            setFlight(flightList[0])
            if (count > 1) {
                setShowFlightList(true)
            }
        } else {
            setView(View.ZeroFlights)
        }
    }, [flightList])

    const _onBasketChange = (newBasket: BasketDTO[]) => {
        setBasket(newBasket)
    }

    const _onBasketClick = () => {
        setView(View.User)
    }

    const _onFlightSelect = () => {
        setShowFlightList(false)
    }
    const _onFlightClick = () => {
        setShowFlightList(true)
    }
    const _selectFlight = (flight: FlightDTO) => () => {
        setFlight(flight)
    }
    const _onCustomerNameChange = (val: string) => {
        setCustomerName(val.trim())
    }
    const _onCustomerSurnameChange = (val: string) => {
        setCustomerSurname(val.trim())
    }
    const _onCustomerPNRChange = (val: string) => {
        setCustomerPNR(val.trim())
    }
    const _onPhoneChange = (val: string) => {
        setPhone(val.trim())
    }
    const _flightsDialogBody = (
        <>
            {flightList.map(e => <div
                key={e.Id}
                className={`${cls.item} ${flight?.Id === e.Id ? cls.active : ''}`}
                onClick={_selectFlight(e)}>
                {flight?.Id === e.Id && <FontAwesomeIcon className={cls.icon}
                                                         icon={faCheck} />} {e.AirlineRcd}{e.FlightNumber} ({e.DepartureAirport} - {e.ArrivalAirport})
                - {DateTimeService.formatUtc(e.ScheduledTimeDepartureLt, 'DDMMMYY')} -
                ETD {DateTimeService.formatUtc(e.ScheduledTimeDepartureLt, 'HH:mm')}
            </div>)}
        </>
    )

    const _userDialogBody = (
        <>
            <div className={cls.basket}>
                {basket.map(b => {
                    return (
                        <div className={cls.basketItem} key={b.service.Code}>
                            <div className={cls.basketName}><b>{b.count}x</b> {b.service.DisplayName}</div>
                            <div
                                className={cls.basketPrice}>{(b.service.Price * b.count).toFixed(2)} {airport.Currency.toUpperCase()}</div>
                        </div>
                    )
                })}
                <div className={cls.basketTotal}>Total
                    Price: {basket.map(b => b.service.Price * b.count).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)} {airport.Currency.toUpperCase()}</div>
            </div>
            <TextControl wrapClass={cls.field} isAutoFocus={true} label="First Name *" value={customerName}
                         onValueChange={_onCustomerNameChange} />
            <TextControl wrapClass={cls.field} label="Last Name *" value={customerSurname}
                         onValueChange={_onCustomerSurnameChange} />
            <PhoneInput
                country={'ch'}
                preferredCountries={[...new Set<string>(['ch', 'de', 'at', airport.CountryTwoLetterCode?.toLowerCase() || 'ch'])]}
                value={phone}
                enableSearch
                placeholder="+41 XX XXX XX XX"
                disableSearchIcon
                searchPlaceholder="Search..."
                containerClass={cls.phoneContainer}
                inputClass={cls.phoneInput}
                buttonClass={cls.phoneCountryBtn}
                searchClass={cls.phoneSearch}
                onChange={_onPhoneChange}
            />
            <TextControl wrapClass={cls.field} label="Passenger Name Record" value={customerPNR}
                         onValueChange={_onCustomerPNRChange} />
        </>
    )
    const onFinishClick = () => {
        setView(View.Services)
        setPayment(null)
        setCustomerName('')
        setCustomerSurname('')
        setCustomerPNR('')
        setPhone('')
        setBasket([])
        setStatus(null)
        setStatusTimeout(0)
    }

    const _onUserView = () => {
        setView(View.User)
    }

    useEffect(() => {
        let timer = 0
        if (statusTimeout && payment)
            timer = window.setTimeout(async () => {
                const { data } = await ApiService.getData<PaymentStatusDTO>(ApiUrls.PaymentStatusById(payment.Id), null)
                setStatus(data)
                if (data.State === PaymentState.Ordered) {
                    setStatusTimeout(statusTimeout + 1)
                } else {
                    setStatusTimeout(0)
                }
            }, _statusCheckInterval)

        return () => clearTimeout(timer)

    }, [statusTimeout, payment])

    const title = flight ? `${flight.AirlineRcd}${flight.FlightNumber} (${flight.DepartureAirport} - ${flight.ArrivalAirport}) - ${DateTimeService.formatUtc(flight.ScheduledTimeDepartureLt, 'DDMMMYY')} - ETD ${DateTimeService.formatUtc(flight.ScheduledTimeDepartureLt, 'HH:mm')}` : ''
    const _onPay = async () => {
        const params = {
            flightId: flight?.Id,
            airport: `${flight?.DepartureAirport} - ${flight?.ArrivalAirport}`,
            customerName,
            customerSurname,
            pnr:customerPNR,
            phone,
            products: basket.map(b => {
                return { code: b.service.Code, quantity: b.count, departureDateLt: flight?.ScheduledTimeDepartureLt }
            }),
        }
        const { data } = await ApiService.postData<PaymentDTO>(ApiUrls.Payment, params, { completion: loaderStore.processLoader })
        setPayment(data)
        setStatusTimeout(1)
    }
    const _onUserCancel = () => {
        setView(View.Services)
    }
    const _flightsDialogActions: ModalAction[] = [{ label: 'Ok', onClick: _onFlightSelect }]
    const _userDialogActions: ModalAction[] = [{
        label: 'Cancel',
        onClick: _onUserCancel,
        className: cls.mrAuto
    }, { label: 'Pay', onClick: _onPay, disabled: !customerName || !customerSurname }]
    const _onResetClick = () => {
        setView(View.Reset)
    }
    const _resetNode = (
        <div className={cls.reset} onClick={_onResetClick}>
            <FontAwesomeIcon icon={faArrowRotateBackward} />
        </div>
    )

    const dataTransURL = config.api.dataTrans + payment?.TransactionId

    const _resetApp = () => {
        LocalStorageClear()
        window.location.reload()
    }

    const _onCancelResetClick = () => {
        setView(flightList.length ? View.Services : View.ZeroFlights)
    }
    const _confirmationBody = (
        <div className={cls.modal}>Application will be reset. <br />Please confirm action</div>
    )

    const _confirmationActions: ModalAction[] = [{
        label: 'Cancel',
        onClick: _onCancelResetClick,
        className: cls.mrAuto
    }, { label: 'Reset App', onClick: _resetApp }]

    if ((view === View.ZeroFlights) || (!flightList.length && view === View.Reset)) {
        return (
            <>
                <div className={cls.emptyText}>No Flights available.</div>
                <div className={cls.emptyReset}>
                    {_resetNode}
                </div>
                <Button className={cls.emptyAction} label="Refresh" onClick={getFlightList} />
                <ModalDialog show={view === View.Reset} title="Confirmation" body={_confirmationBody}
                             actions={_confirmationActions} />
            </>
        )
    }

    return (
        <div className={cls.root}>
            <Header title={title} currency={airport.Currency.toUpperCase()} basket={basket}
                    onBasketClick={_onBasketClick} onFlightClick={_onFlightClick}
                    canSwitchFlight={flightList.length > 1} />
            <Body airport={airport} basket={basket} onBasketChange={_onBasketChange} />
            <div className={cls.footer}>
                {!payment && _resetNode}
                <Button className={cls.action} label="go next" disabled={!basket.length} onClick={_onUserView} />
            </div>
            <ModalDialog show={showFlightList} title="Available flights" body={_flightsDialogBody}
                         actions={_flightsDialogActions} />
            <ModalDialog show={view === View.User} title="Payer Information" body={_userDialogBody}
                         actions={_userDialogActions} bodyClassname={cls.payerDialogBody} />
            <ModalDialog show={view === View.Reset} title="Confirmation" body={_confirmationBody}
                         actions={_confirmationActions} />
            {payment &&
                <div className={cls.payment}>
                    <div className={cls.qrNode}>
                        {status?.State === PaymentState.Payed &&
                            <FontAwesomeIcon className={cls.payedIcon} icon={faCheckCircle} />}
                        <QRCode className={cls.qr} value={dataTransURL} size={512} viewBox="0 0 512 512" />
                    </div>
                    <ButtonLink className={cls.payAction} hidden={status?.State !== PaymentState.Ordered}
                                isInline={true} label="Pay On Device" target="_blank" href={dataTransURL} />
                    <div className={cls.statusNode}>
                        {(!status || status.State === PaymentState.Ordered) && 'Payment in process'}
                        {status && status.State === PaymentState.Payed && 'Payment completed'}
                        {status && status.State === PaymentState.Failed && 'Payment failed!'}
                        {status && status.State === PaymentState.Canceled && 'Payment canceled!'}
                        <span hidden={status?.State !== PaymentState.Ordered} className={cls.dotPulse}></span>
                    </div>
                    <Button className={cls.emptyAction} label="Close" onClick={onFinishClick} />
                </div>
            }
        </div>
    )
}
