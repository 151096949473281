import { FC } from 'react'
import cls from './_dialog.module.scss'
import { Button } from './Button'

export type ModalAction = {
    label: string
    disabled?: boolean
    className?: string
    onClick: () => void
}

type DialogProps = {
    show: boolean
    title: string
    body?: JSX.Element
    actions: ModalAction[]
    bodyClassname?: string;
}

export const ModalDialog: FC<DialogProps> = ({ show, actions, body, title, bodyClassname }) => {
    if (!show) return null

    const bodyClsList = [cls.body];
    if (bodyClassname) {
        bodyClsList.push(bodyClassname);
    }

    return (
        <div className={cls.root}>
            <div className={cls.dialog}>
                <div className={cls.title}>{title}</div>
                <div hidden={!body} className={bodyClsList.join(' ')}>{body}</div>
                <div className={cls.actions}>
                    {actions.map(e => {
                        return (
                            <Button
                                className={e.className}
                                isInline={true}
                                key={e.label}
                                label={e.label}
                                onClick={e.onClick}
                                disabled={e.disabled} />
                        )
                    })}</div>
            </div>
        </div>
    )
}
