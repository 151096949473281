import { FC } from 'react'
import { faAngleDown, faBasketShopping } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cls from './_header.module.scss'
import { BasketDTO } from '../../AppModels'

type HeaderProps = {
    title: string
    currency: string
    basket: BasketDTO[]
    onBasketClick: () => void
    onFlightClick: () => void
    canSwitchFlight: boolean
}

export const Header: FC<HeaderProps> = ({ title, basket, onFlightClick, canSwitchFlight, currency, onBasketClick }) => {
    const count = basket.map(e => e.count).reduce((partialSum, a) => partialSum + a, 0) || 0
    let titleNode = <div className={cls.name}>{title}</div>
    if (canSwitchFlight) {
        titleNode = <div className={cls.dropdown} onClick={onFlightClick}>{title} <FontAwesomeIcon className={cls.icon}
                                                                                                   icon={faAngleDown} />
        </div>
    }
    const totalPrice = basket.map(b => b.service.Price * b.count).reduce((partialSum, a) => partialSum + a, 0)
    return (
        <div className={cls.root}>
            {titleNode}
            <div className={cls.basket} onClick={count ? onBasketClick : undefined}>
                {!!count &&
                    <>
                        <div className={cls.count}>{count}</div>
                        <div className={cls.total}>{currency} {totalPrice.toFixed(2)}</div>
                    </>
                }
                <FontAwesomeIcon className={cls.icon} icon={faBasketShopping} />
            </div>
        </div>
    )
}
