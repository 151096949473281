import { computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { AccountInfo } from '@azure/msal-common';
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import config from '../config.json';
import { UserRole } from '../AppModels';
import { LocalStorageGet, LocalStorageRemove, LocalStorageSet } from '../services';
import { Routing, StoredKeys } from '../AppConstants';
import { loaderStore } from '../index';

type JwtToken = {
    aio: string;
    amr: string[];
    aud: string;
    exp: number;
    family_name: string;
    given_name: string;
    iat: number;
    ipaddr: string;
    iss: string;
    name: string;
    nbf: number;
    nonce: string;
    oid: string;
    rh: string;
    roles: string[];
    sub: string;
    tid: string;
    unique_name: string;
    upn: string;
    uti: string;
    ver: string;
};

export class UserStore {
    @observable currentUser: AccountInfo | null = null;
    @observable currentToken: string = '';
    private _msalInstance: PublicClientApplication;

    constructor (msalInstance: PublicClientApplication) {
        makeAutoObservable(this);
        this._msalInstance = msalInstance;
    }

    @computed
    public get userRoles () {
        return this.currentUser?.idTokenClaims?.roles || [];
    }

    @computed
    public get isStation () {
        return !this.currentUser || this.userRoles.includes(UserRole.Admin);
    }

    @computed
    public get isCommercial () {
        return this.userRoles.includes(UserRole.Commercial);
    }

    @computed
    public get isGround () {
        return this.userRoles.includes(UserRole.Ground);
    }
    
    @computed
    public get isAdmin () {
        return this.userRoles.includes(UserRole.Admin);
    }

    @computed
    public get isTokenValid () {
        return !!this.currentToken && this._validateToken(this.currentToken);
    }

    @computed
    public get isAuthorized () {
        return !!this.currentUser && this.isTokenValid;
    }
    
    private _redirectToLogin(){
        if (!window.location.pathname.startsWith(Routing.Login)) {
            window.location.href = window.location.origin + Routing.Login;
        }
    }
    
    public clearUserData () {
        this.currentUser = null;
        this.currentToken = '';
        LocalStorageRemove(StoredKeys.Token);
        LocalStorageRemove(StoredKeys.User);
        LocalStorageRemove(StoredKeys.ActiveTab);
    }

    public restoreAccount () {
        const currentAccounts = this._msalInstance.getAllAccounts();
        const token = LocalStorageGet<string>(StoredKeys.Token);
        if (currentAccounts.length === 1 && token && this._validateToken(token || '')) {
            this._setUserData(currentAccounts[0], token);
        } else {
            this.clearUserData();
            this._redirectToLogin()
        }
    }

    public logOut = async () => {
        if (this.currentUser) {
            const logoutRequest = {
                account: this._msalInstance.getAccountByHomeId(this.currentUser.homeAccountId),
                postLogoutRedirectUri: window.location.origin,
                onRedirectNavigate: this.clearUserData
            };
            loaderStore.showAppLoader();
            await this._msalInstance.logoutRedirect(logoutRequest);
        }
    };

    public logIn = () => {
        this.clearUserData();
        this._msalInstance.loginPopup(config.loginRequest).then(this._handleAuthResponse).catch((e) => {
            console.log(`ErrorPopup:`, e);
        });
    };

    private _handleAuthResponse = (response: AuthenticationResult) => {
        if (response !== null) {
            const { account, accessToken } = response;
            this._setUserData(account, accessToken);
        }
    };

    private _parseJwtToken (token: string): JwtToken | null {
        if (!token) return null;

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64).split('').map((c: string) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join('')
        );

        return JSON.parse(jsonPayload);
    }

    private _setUserData (account: AccountInfo, accessToken: string) {
        runInAction(() => {
            this.currentUser = account;
            this.currentToken = accessToken;
            LocalStorageSet(StoredKeys.Token, accessToken);
            LocalStorageSet(StoredKeys.User, account);
            const roles = account.idTokenClaims?.roles || [];
            if (window.location.pathname.startsWith(Routing.Login)) {
                let redirectTo = Routing.Home;
                if (roles.includes(UserRole.Ground) || roles.includes(UserRole.Commercial) || roles.includes(UserRole.Admin)) {
                    redirectTo = Routing.Configuration;
                }
                window.location.href = window.location.origin + redirectTo;
            }
        });
    }

    private _validateToken (token: string) {
        const payload = this._parseJwtToken(token);
        if (!payload)
            return false;
        const nowUtc = Date.now();
        const expireAt = new Date(payload.exp * 1000);
        return expireAt.getTime() > nowUtc;
    };

}