import { FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import cls from './_datePicker.module.scss'

type DatePickerProps = ReactDatePickerProps

export const DatePicker: FC<DatePickerProps> = ({wrapperClassName, ...rest }) => {
    return (
        <ReactDatePicker
            dateFormat="dd.MM.yyyy"
            wrapperClassName={cls.wrapper}
            {...rest}
        />
    )
}
