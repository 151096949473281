import { action, makeAutoObservable } from 'mobx';
import { PromiseCompletion } from '../services';

export class LoaderStore {
    public appLoaderElement: HTMLElement | null = null;
    appLoader: PromiseCompletion = new PromiseCompletion();
    bodyLoader: PromiseCompletion = new PromiseCompletion();
    processLoader: PromiseCompletion = new PromiseCompletion();

    constructor () {
        makeAutoObservable(this);
    }

    @action
    public hideAppLoader () {
        const loaderNode = this.appLoaderElement;
        if (!loaderNode) return;
        if (!loaderNode.classList.contains('hide')) {
            loaderNode.classList.add('hide');
        }
    }

    @action
    public showAppLoader () {
        const loaderNode = this.appLoaderElement;
        if (!loaderNode) return;

        loaderNode.classList.remove('invisible');
        window.setTimeout(() => {
            loaderNode.classList.remove('hide');
        }, 0);
    }

}