import { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { userStore } from "../../index";
import { TabItem, Tabs } from "../Tabs/Tabs";
import { Products } from "./Products/Products";
import { Regions } from "./Regions/Regions";
import { Stations } from "./Stations/Stations";
import "react-datepicker/dist/react-datepicker.css";
import { ReportsConfigurationPage } from './Reports/Reports';

export const Configuration = observer(() => {
    useEffect(() => {
        userStore.restoreAccount()
    }, []);
    if(!userStore.isAuthorized) return;
    let tabs: TabItem[] = []
    if(userStore.isCommercial || userStore.isAdmin){
        tabs = [
            {
                title: 'Products',
                body: <Products />
            },
            {
                title: 'Regions',
                body: <Regions />
            },
            {
                title: 'Stations',
                body: (
                    <Stations />
                )
            },
        ]
    }
    if(userStore.isGround || userStore.isAdmin){
        tabs.push(
            {
                title: 'Reports',
                body: <ReportsConfigurationPage />
            }
        )
    }
    return <Tabs tabs={tabs} />
})