export function LocalStorageGet<T> (key: string): T | null {
    if (global.localStorage) {
        const value = global.localStorage.getItem(key);
        try {
            return value ? JSON.parse(value) as T : null;
        } catch (err) {
            LocalStorageRemove(key);
            return null;
        }
    }
    return null;
}

export function LocalStorageSet<T> (key: string, value: T) {
    if (global.localStorage) {
        global.localStorage.setItem(key, JSON.stringify(value));
    }
}

export function LocalStorageRemove (key: string) {
    if (global.localStorage) {
        global.localStorage.removeItem(key);
    }
}

export function LocalStorageClear () {
    if (global.localStorage) {
        global.localStorage.clear();
    }
}